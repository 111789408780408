<template>
  <div class="container-suite">
    <Header/>
    <div class="container">
      <div class="c-left">
        <img class="margin-img" src="../assets/images/download-left.png">
      </div>
      <div class="content">
        <div class="one">
          <div class="title-line1">珍想记录</div>
          <div class="title-line2">随时随地记录所见所思</div>
          <button class="free-btn">即将发布</button>
          <div class="description">
            <div class="description-1">珍想记录 是一款信息存入软件，在移动端用最便捷快速的方式记录</div>
            <div class="description-2">几乎一切信息；在桌面端整理归类输入的信息</div>
          </div>
        </div>
        <div class="two">
          <ul class="two-content">
            <li class="item" v-for="(item,index) in show"  v-bind:key="index+'show'">
              <img class="img" :src="item.imgUrl"/>
              <div class="sub-content">
                <div class="line1">{{item.content.line1}}</div>
                <div class="line2">{{item.content.line2}}</div>
                <div class="line3">{{item.content.line3}}</div>
              </div>
            </li>
          </ul>
        </div>
        <div class="three">
          <ul class="content">
            <li class="item" v-for="(item,index) in cloud" v-bind:key="index + item.title">
              <img class="img" :src="item.imgUrl"/>
              <div class="title">{{item.title}}</div>
            </li>
          </ul>
        </div>
      </div>
      <div class="c-right">
        <img class="margin-img" src="../assets/images/download-right.png"/>
      </div>
    </div>
    <div class="bottom">
      <a href="https://beian.miit.gov.cn" target="_blank">京ICP备2023007351号</a>
    </div>
  </div>
</template>

<script>
import Header from "./Header"
export default {
  name: 'IndexPage',
  components: {
    Header
  },
  data: () => {
    return {
      show: [
        {
          imgUrl: require('../assets/images/iphone-1.jpg'),
          content: {
            line1: '利用widget，',
            line2: '在手机任何界面',
            line3: '都可以快捷呼出记录'
          }
        },
        {
          imgUrl: require('../assets/images/iphone-2.jpg'),
          content: {
            line1: '流畅的树状编辑器',
            line2: '顺畅输入你想到见到的',
            line3: '文本、链接、图片、音频'
          }
        },
        {
          imgUrl: require('../assets/images/iphone-3.jpg'),
          content: {
            line1: '翻阅列表',
            line2: '记录的内容可以',
            line3: '按照标签方式整理'
          }
        },
      ],
      cloud: [
        {
          title: '开通云同步功能，在桌面设备上可以就编辑整理所记录的内容',
          imgUrl: require('../assets/images/mac.jpg'),
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
ul{list-style: none;}
body {
  margin: 0;
  overflow: hidden;
}
.container-suite {
  background-color: #202124;
  position: absolute;
  zoom: 45%;
  overflow: hidden;
}
@media screen and(min-width: 700px) {
  .container-suite {
    background-color: #202124;
    position: relative;
    width: 100vw;
    zoom: 100%;
  }
}
.container {
  display: flex;
  align-content: center;
  justify-content: space-between;
}

.one {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.title-line1,.title-line2 {
  font-size: 0.75rem;
  @media screen and(min-width: 700px) {
    font-size: 75px;
  }
  color: #fafafa;
}
.title-line2 {

  font-size: 0.60rem;
  @media screen and(min-width: 700px) {
    font-size: 60px;
    margin-top: -25px;
  }
}
.title-line3 {
  font-size: 0.20rem;
  @media screen and(min-width: 700px) {
    font-size: 20px;
  }
  color: #fafafa;
  opacity: 0.55;
  margin-top: -10px;
}
.free-btn {
  margin-top: 10px;
  border: none;
  width: 291px;
  height: 75px;
  background-color: #42b983;
  border-radius: 10px;
  font-size: 0.25rem;
  @media screen and(min-width: 700px) {
    font-size: 30px;
  }
  color: #fafafa;
  cursor: pointer;
  box-shadow: 0px 1px 1px 0px
  rgba(0, 0, 0, 0.11);
}
.description {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  .description-1, .description-2 {
    font-size: 0.20rem;
    @media screen and(min-width: 700px) {
      font-size: 20px;
    }
    color: #A2A2A2;
  }
}


.margin-img {
  width: 131px;
  height: 598px;
}
.c-right {
  .margin-img {
    width: 120px;
    height: 528px;
  }
}
.two {
  margin-top: 50px;
  height: 550px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .title {
    font-size: 0.32rem;
    @media screen and(min-width: 700px) {
      font-size: 32px;
    }
    color: #fafafa;
    opacity: 0.55;
  }

  .two-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 40px;
    width: 100%;

    @media screen and(max-width: 700px) {
      margin-left: -40px;
    }

    .item:first-child {
      margin-left: 0;
    }
    .item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .img {
        width: 160px;
        height: 348px;
      }

      .sub-title {
        margin-top: 16px;
        font-size: 0.22rem;
        @media screen and(min-width: 700px) {
          font-size: 22px;
        }
        color: #fafafa;
      }

      .sub-content {
        margin-top: 14px;
        font-size: 0.10rem;
        @media screen and(min-width: 700px) {
          font-size: 14px;
        }
        color: #A2A2A2;
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }
  }
}
.three {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 100px;
  //margin-top: 30px;
  //@media screen and(min-width: 700px) {
  //  margin-top: 50px;
  //}

  &>.title {
    font-size: 0.32rem;
    @media screen and(min-width: 700px) {
      font-size: 32px;
    }
    color: #fafafa;
    opacity: 0.55;
  }

  .content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 10px;
    @media screen and(max-width: 700px) {
      margin-left: -40px;
    }

    .item:first-child {
      margin-left: 0;
    }
    .item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-left: 79px;

      .img {
        width: 735px;
        height: 464px;
      }

      .title {
        margin-top: 20px;
        font-size: 0.22rem;
        @media screen and(min-width: 700px) {
          font-size: 22px;
        }
        color: #A2A2A2;
      }
    }
  }
}
.bottom {
  border-top: 1px solid #3a3a3a;
  height: 54px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  font-weight: 300;
  color: rgb(256,256,256);

  a {
    color: inherit;
  }
}
</style>
