<template>
  <IndexPage class="index"/>
</template>

<script>
import IndexPage from "@/components/Index.vue";

export default {
  name: 'App',
  components: {
    IndexPage
  }
}
</script>

<style>
/*#app {*/
/*  font-family: Avenir, Helvetica, Arial, sans-serif;*/
/*  -webkit-font-smoothing: antialiased;*/
/*  -moz-osx-font-smoothing: grayscale;*/
/*  text-align: center;*/
/*  color: #2c3e50;*/
/*  margin-top: 60px;*/
/*}*/
body {
  /*overflow: hidden;*/
  background-color: #202124;
}


</style>
