<template>
  <header class="header-container">
    <div class="logo-text">
      <img class="logo" src="../assets/images/index-logo.png" />
      <div class="zhenxiang-text">珍想</div>
    </div>
    <ul class="navs">
      <li
        class="item"
        v-for="(item, index) in navs"
        v-bind:key="index + 'navs'"
      >
        <a class="item" :href="item.href">
          {{ item.title }}
        </a>
      </li>
    </ul>
  </header>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Header",
  data: () => {
    return {
      navs: [
        {
          href: "#",
          title: "工具箱",
        },
        {
          href: "#",
          title: "同步服务",
        },
        {
          href: "#account",
          title: "账户",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
ul {
  list-style: none;
}
.header-container {
  background-color: #1e1f22;
  display: flex;
  justify-content: space-between;
  align-content: start;
  align-items: center;
  height: 63px;
  padding-top: 10px;
}
.logo-text {
  margin-top: -8px;
  display: flex;
  flex-direction: row;
  font-size: 0.31rem;
  @media screen and(min-width: 700px) {
    font-size: 31px;
  }
  color: #f3f3f3;
  margin-left: 20px;
}
.logo {
  margin-top: 5px;
  width: 63px;
  height: 32px;
}
.zhenxiang-text {
  margin-left: 2px;
}
.navs {
  display: flex;
  flex-direction: row;
  margin-right: 35px;
}
.item {
  margin-left: 53px;
  font-size: 0.18rem;
  @media screen and(min-width: 700px) {
    font-size: 18px;
  }
  color: #f3f3f3;
}
</style>
